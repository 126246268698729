export const camelCase = (text: string): string =>
  text.replace(/([-_][a-z])/gi, t =>
    t
      .toUpperCase()
      .replace("-", "")
      .replace("_", "")
  )
export const camelCaseify = (obj: any): any =>
  Object.entries(obj).reduce<any>(
    (prev, [key, value]) => ({
      ...prev,
      [camelCase(key)]: value
    }),
    {}
  )

export const snakeCase = (text: string): string =>
  text
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase()
export const snakeCaseify = (obj: any): any =>
  Object.entries(obj).reduce<any>(
    (prev, [key, value]) => ({
      ...prev,
      [snakeCase(key)]: value
    }),
    {}
  )
